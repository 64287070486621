msgid ""
msgstr ""
"POT-Creation-Date: 2024-03-19 18:08+0000\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: test\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

msgid "{adFormatName} are not available for self-managed campaigns. Please contact sales to inquire."
msgstr ""

msgid "{billingType} price must be {price} or higher"
msgstr ""

msgid "{campaignFormat} pricing configuration option(s):"
msgstr ""

msgid "{length}/{maxLength} characters"
msgstr ""

msgid "{type} status cannot be updated"
msgstr ""

msgid "*Limited time only. Available to new advertisers, and those who ran campaigns prior to September 30, 2023. Eligible for Push Notification and Newsfeed ads."
msgstr ""

msgid "+{count} more"
msgstr ""

msgid "1 Day"
msgstr ""

msgid "30 Days"
msgstr ""

msgid "401 Forbidden"
msgstr ""

msgid "7 Days"
msgstr ""

msgid "A forbidden error was returned. You may need to log out and back in again."
msgstr ""

msgid "A login email is on the way"
msgstr ""

msgid "About Brave Ads"
msgstr ""

msgid "Account"
msgstr ""

msgid "Account Balance"
msgstr ""

msgid "Account Balance will be applied to this campaign"
msgstr ""

msgid "Account Settings"
msgstr ""

msgid "Activate"
msgstr ""

msgid "Ad"
msgstr ""

msgid "Ad body is required"
msgstr ""

msgid "Ad body must be less than 60 characters"
msgstr ""

msgid "Ad description is required"
msgstr ""

msgid "Ad format"
msgstr ""

msgid "Ad Format"
msgstr ""

msgid "Ad image dimensions are required"
msgstr ""

msgid "Ad Name is required"
msgstr ""

msgid "Ad Set"
msgstr ""

msgid "Ad set name"
msgstr ""

msgid "Ad Sets"
msgstr ""

msgid "Ad sets must have at least one ad"
msgstr ""

msgid "Ad title is required"
msgstr ""

msgid "Ad title must be less than 30 characters"
msgstr ""

msgid "Ad title must be less than 90 characters"
msgstr ""

msgid "Ad type is required"
msgstr ""

msgid "Ad type must be notification or news"
msgstr ""

msgid "Add an existing ad"
msgstr ""

msgid "Add Conversion tracking"
msgstr ""

msgid "Additional Details"
msgstr ""

msgid "Ads"
msgstr ""

#~ msgid "Ads are modular building blocks that can be paired with ad sets to build unique combinations. Your previously approved ads will show here. Select by using the box next to the name. Use the \"Complete selection\" button to finish."
#~ msgstr ""

msgid "Ads are modular building blocks that can be paired with ad sets to build unique combinations. Your previously created ads will show here. Select by using the box next to the name. Use the \"Complete selection\" button to finish."
msgstr ""

#~ msgid "Ads Help Center"
#~ msgstr ""

msgid "Advertiser Name"
msgstr ""

msgid "Advertiser profile"
msgstr ""

msgid "All OS"
msgstr ""

msgid "All time"
msgstr ""

msgid "An ad from my Brave browser"
msgstr ""

msgid "An error has occurred while processing your request."
msgstr ""

msgid "and no additional charges will be incurred."
msgstr ""

msgid "and you will be charged {amountToCharge} USD."
msgstr ""

#~ msgid "and you will not be charged."
#~ msgstr ""

msgid "Android"
msgstr ""

msgid "API keys are used to get data from the reporting endpoints. They are unique to you."
msgstr ""

msgid "Are you sure you want to activate"
msgstr ""

msgid "Are you sure you want to modify \"{creativeName}\"?"
msgstr ""

msgid "Are you sure you want to pause"
msgstr ""

#~ msgid "As effective as Google"
#~ msgstr ""

msgid "At a marketing/media conference"
msgstr ""

msgid "At least one country must be targeted"
msgstr ""

msgid "At least one platform must be targeted"
msgstr ""

msgid "Audiences"
msgstr ""

msgid "Augment your marketing strategy"
msgstr ""

msgid "Automatic interest targeting"
msgstr ""

msgid "Back"
msgstr ""

msgid "Basic Attention Token"
msgstr ""

msgid "BAT holders can now also cash in their tokens to advertise their companies or products on the Brave Ads network."
msgstr ""

msgid "Billing Type"
msgstr ""

msgid "Body"
msgstr ""

msgid "Body Preview"
msgstr ""

msgid "Brave Ads deliver at the browser or search level, in dedicated placements that give your brand more spotlight and better engagement."
msgstr ""

msgid "Brave cannot recover this key, which has been generated in your browser. You will need to confirm this private key on the next step before changes are saved."
msgstr ""

msgid "Brave is an independent company dedicated to building a user-first Web. Our private, feature-rich browser and search engine are used by 70+ million people worldwide, with hundreds of thousands more downloading every day."
msgstr ""

msgid "Brave is where early adopters thrive. We’re celebrating our crypto roots by offering new and returning Brave Ads customers half off their first self-managed campaign if they buy with BAT<0>*</0>"
msgstr ""

msgid "Brave Search Ads"
msgstr ""

#~ msgid "Brave Search ads are built to be easy to manage. Once enabled, brands will benefit from a passive increase in traffic without the need to constantly monitor and tweak campaigns."
#~ msgstr ""

msgid "Brave Search Ads are built to be easy to manage. Once enabled, brands will benefit from a passive increase in traffic without the need to constantly monitor and tweak campaigns."
msgstr ""

#~ msgid "Brave Search ads are built to perform and convert as well as - or better than - Google search ad clicks."
#~ msgstr ""

msgid "Brave Search Ads are built to perform and convert as well as - or better than - Google Search Ad clicks."
msgstr ""

#~ msgid "Brave Search Ads are privacy-preserving, text-based ads that appear at the top of a user’s search results page (SERP). Want to redefine your SEM program, and diversify from Big Tech? Get the first-mover advantage and start targeting your brand’s most important keywords today."
#~ msgstr ""

#~ msgid "Brave Search Ads can reach audiences who use ad blockers, and don’t use traditional search engines."
#~ msgstr ""

#~ msgid "Brave Search Ads convert as well as—or better than—Google Search ad clicks."
#~ msgstr ""

#~ msgid "Brave Search is the fastest growing independent search engine since Bing, with more than 9 billion searches each year (and growing). It’s the default search engine for Brave’s tens of millions of worldwide users, and available in any other browser at <0>search.brave.com</0>"
#~ msgstr ""

msgid "Brave’s 70+ million cord cutters, and tech savvy early adopters want privacy and performance over tracking and lag. Hundreds of thousands more make the switch every day. Want them to see your brand? Start your first campaign in minutes, and with as little as $500."
msgstr ""

msgid "Budget"
msgstr ""

msgid "Business name"
msgstr ""

msgid "Business name is required"
msgstr ""

msgid "Business website"
msgstr ""

msgid "Business Website is required"
msgstr ""

msgid "Call To Action"
msgstr ""

msgid "Call to action is required"
msgstr ""

msgid "Call to action must be less than 15 characters"
msgstr ""

msgid "Call to Action text"
msgstr ""

msgid "Campaign"
msgstr ""

msgid "Campaign creation is currently unavailable"
msgstr ""

msgid "Campaign does not exist, or cannot be edited. Please try again later."
msgstr ""

msgid "Campaign Drafts"
msgstr ""

msgid "Campaign Format is required"
msgstr ""

msgid "Campaign has not started yet"
msgstr ""

msgid "Campaign Name"
msgstr ""

msgid "Campaign Name is required"
msgstr ""

msgid "Campaign Settings"
msgstr ""

msgid "Campaign Summary"
msgstr ""

msgid "Campaigns"
msgstr ""

msgid "Cancel"
msgstr ""

msgid "cannot create api key"
msgstr ""

msgid "cannot create session"
msgstr ""

msgid "Check eligibility"
msgstr ""

#~ msgid "Check if your brand is eligible for a <0>free trial</0> of Brave Search ads. <1>Try Brave Search</1> to see why our users choose us over Google and Bing."
#~ msgstr ""

msgid "Check if your brand is eligible for a <0>free trial</0> of Brave Search Ads. <1>Try Brave Search</1> to see why our users choose us over Google and Bing."
msgstr ""

msgid "Check your brand's eligibility"
msgstr ""

msgid "Check your spam folder or <0>return to the login page</0> to try again."
msgstr ""

msgid "Choose"
msgstr ""

msgid "Choose a format for the campaign you would like to run."
msgstr ""

msgid "Choose File"
msgstr ""

msgid "City"
msgstr ""

msgid "City is required"
msgstr ""

msgid "Click Here!"
msgstr ""

msgid "Click on the secure login link in the email to access your Brave Ads account."
msgstr ""

msgid "Click the continue button below to complete the login process."
msgstr ""

msgid "Click Through Rate"
msgstr ""

msgid "Click-through Conversions"
msgstr ""

msgid "Clicks"
msgstr ""

msgid "Clone"
msgstr ""

msgid "Clone {campaignName}"
msgstr ""

msgid "Clone campaign: \"{campaignName}\"?"
msgstr ""

msgid "Cloning a campaign will take all properties including ad sets and ads, and create a new draft campaign with them."
msgstr ""

msgid "Close"
msgstr ""

msgid "Close Campaign"
msgstr ""

msgid "Closing a campaign will immediately stop it from running. Once it has stopped running, any remaining funds will be transferred back to your account in 24-48 hours."
msgstr ""

msgid "Complete"
msgstr ""

msgid "Complete selection"
msgstr ""

msgid "Complete your business profile to continue"
msgstr ""

msgid "Congratulations! 🎉"
msgstr ""

msgid "Contact"
msgstr ""

msgid "Contact sales"
msgstr ""

msgid "Contact Sales"
msgstr ""

msgid "Content"
msgstr ""

msgid "Continue"
msgstr ""

msgid "Conversion"
msgstr ""

msgid "Conversion Observation Window"
msgstr ""

msgid "Conversion Rate"
msgstr ""

#~ msgid "Conversion reporting change"
#~ msgstr ""

#~ msgid "Conversion reporting for Brave Ads has changed to better uphold user privacy and security. Campaigns with conversion URLs that violate the newly clarified requirements for conversion URLs will now automatically be paused until their URLs have been updated. Please review the guidelines and take any necessary action to prevent campaign disruption. New guidelines can be found at the <0/>"
#~ msgstr ""

msgid "Conversion type must be Post Click"
msgstr ""

#~ msgid "Conversion type must be Post Click or Post View"
#~ msgstr ""

msgid "Conversion type required."
msgstr ""

msgid "Conversion URL must end in trailing asterisk (*)"
msgstr ""

msgid "Conversion URL must not contain any whitespace"
msgstr ""

msgid "Conversion URL must start with https://"
msgstr ""

msgid "Conversion URL Pattern"
msgstr ""

msgid "Conversion URL required."
msgstr ""

msgid "Conversions"
msgstr ""

msgid "Convert customers with AI-powered search ads"
msgstr ""

msgid "Copy this and keep this safe!"
msgstr ""

msgid "Cost per Acquisition"
msgstr ""

msgid "Cost per click"
msgstr ""

msgid "Could not logout at this time. Please try again later."
msgstr ""

msgid "Country"
msgstr ""

msgid "Country is required"
msgstr ""

msgid "Country Targeting"
msgstr ""

msgid "CPA"
msgstr ""

msgid "CPC (Clicks)"
msgstr ""

msgid "CPM (Impressions)"
msgstr ""

msgid "CPSV (Site Visits)"
msgstr ""

msgid "Create & Submit For Approval"
msgstr ""

msgid "Create ad"
msgstr ""

msgid "Create new ad"
msgstr ""

msgid "Create new keypair?"
msgstr ""

msgid "create now"
msgstr ""

msgid "created"
msgstr ""

msgid "CTR"
msgstr ""

msgid "Custom"
msgstr ""

msgid "Daily"
msgstr ""

msgid "Dashboard"
msgstr ""

msgid "Data not valid for this private key"
msgstr ""

msgid "Decrypt Conversion Data?"
msgstr ""

msgid "Define how you want your campaign to run."
msgstr ""

msgid "Define the number of days Brave will observe conversions and attribute them to the campaign."
msgstr ""

msgid "Disabled because you haven’t made any changes"
msgstr ""

msgid "Disabled due to validation errors"
msgstr ""

msgid "Discard ad"
msgstr ""

msgid "Discard campaign"
msgstr ""

msgid "Dismissal Rate"
msgstr ""

msgid "Dismissals"
msgstr ""

msgid "Documentation can be found <0>here</0> on how to use the API."
msgstr ""

msgid "Domain"
msgstr ""

msgid "Domain is required"
msgstr ""

msgid "Domain must not contain any whitespace"
msgstr ""

msgid "Domain to advertise"
msgstr ""

msgid "Don’t see the email?"
msgstr ""

#~ msgid "Don’t see the email? Check your spam folder or <0>try again.</0>"
#~ msgstr ""

#~ msgid "Don't show this message again"
#~ msgstr ""

msgid "Download Report"
msgstr ""

msgid "Downvotes"
msgstr ""

msgid "Draft"
msgstr ""

msgid "Drafts"
msgstr ""

msgid "Edit"
msgstr ""

msgid "Edit {campaignName}"
msgstr ""

msgid "Edit ad"
msgstr ""

msgid "Email"
msgstr ""

msgid "Email address is required"
msgstr ""

msgid "End"
msgstr ""

msgid "End Date"
msgstr ""

msgid "End date is required"
msgstr ""

msgid "End date must be after start date"
msgstr ""

msgid "End Time"
msgstr ""

msgid "Enter a URL that indicates a desired action you want to measure, like a subscription or purchase confirmation page. Brave will count unique visits to that page as conversions if a user has seen or clicked your ad."
msgstr ""

msgid "Enter Ads Manager"
msgstr ""

msgid "Enter your email address to get a secure login link. Use this link to access your Brave Ads account."
msgstr ""

msgid "Escape the low CTR of display banners"
msgstr ""

msgid "Example - https://brave.com/brave-rewards/"
msgstr ""

msgid "Export"
msgstr ""

msgid "Failed to Fetch"
msgstr ""

msgid "Failed to load image"
msgstr ""

msgid "Failed to update profile: {0}"
msgstr ""

msgid "Failed to upload image"
msgstr ""

msgid "Feedback"
msgstr ""

msgid "File upload complete for \"{fileName}\""
msgstr ""

msgid "Filter ads by name..."
msgstr ""

msgid "Finalize & submit"
msgstr ""

msgid "First party tracking acknowledgement is required"
msgstr ""

msgid "For businesses of all sizes. Create your campaign in minutes, starting with as little as $500 per month."
msgstr ""

msgid "Format"
msgstr ""

msgid "From"
msgstr ""

msgid "From a Brave sales representative"
msgstr ""

msgid "From a friend/colleague"
msgstr ""

msgid "From an influential person I follow online"
msgstr ""

msgid "Full Landing Page URL"
msgstr ""

msgid "Full name"
msgstr ""

msgid "Full Name"
msgstr ""

msgid "Full name is required"
msgstr ""

msgid "General Ad Inquiries"
msgstr ""

msgid "Generate"
msgstr ""

msgid "Generate a keypair for your account. Brave Ads will use your account’s public key to sign and encrypt conversion data. Only your organization will have access to the private key, which can be used to decrypt and view conversion data."
msgstr ""

msgid "Generate a new API key?"
msgstr ""

msgid "Generate API Key"
msgstr ""

msgid "Generating a new API key will result in the deactivation of your previous key, rendering it unusable for future requests. Make sure to update your code with the new key to avoid disruptions in your application’s functionality."
msgstr ""

msgid "Get in touch with our team"
msgstr ""

msgid "Get in touch."
msgstr ""

msgid "Get incremental traffic with paid ads on the world's fastest growing search engine."
msgstr ""

msgid "Get login link"
msgstr ""

msgid "Get started"
msgstr ""

msgid "Get to your first million users with powerful ad placements on the world’s fastest-growing alternative browser & search engine."
msgstr ""

msgid "Have specific questions?"
msgstr ""

msgid "Help Center"
msgstr ""

msgid "Hi"
msgstr ""

msgid "Hourly"
msgstr ""

msgid "I have reviewed and agree to the <0>Advertiser Privacy Policy</0> and platform <1>Terms of Service</1> applicable to Brave Ads."
msgstr ""

msgid "I understand that first-party analytics are necessary for independent reporting."
msgstr ""

msgid "I understand that I can only run ad campaigns in English, Spanish, and Portuguese."
msgstr ""

msgid "I understand that payment is required before ad campaigns can be launched."
msgstr ""

msgid "I would like to receive marketing emails about new features and promotions from Brave Ads"
msgstr ""

msgid "Id"
msgstr ""

msgid "Image"
msgstr ""

msgid "Image failed to load"
msgstr ""

msgid "Image URL must be hosted on our private CDN"
msgstr ""

msgid "Image URL must be valid"
msgstr ""

msgid "Images"
msgstr ""

msgid "Impressions"
msgstr ""

msgid "In a blog post/news article"
msgstr ""

msgid "In a newsletter I subscribe to"
msgstr ""

msgid "In a post by Brave on LinkedIn"
msgstr ""

msgid "In a video on YouTube"
msgstr ""

msgid "In your local time this is"
msgstr ""

msgid "Include campaigns that ended over 6 months ago"
msgstr ""

#~ msgid "Incremental"
#~ msgstr ""

msgid "Invalid format"
msgstr ""

msgid "invalid session"
msgstr ""

msgid "Invalid Session"
msgstr ""

msgid "Invalid Token"
msgstr ""

msgid "iOS"
msgstr ""

msgid "is not"
msgstr ""

msgid "It is a modern <0>advertiser-first</0> ad platform made to help brands reach new users. Brave Ads are discrete, unobtrusive first-party ads built directly into the browser and search engine, with familiar formats like push notifications and news-feed ads. Brave’s innovative on-device targeting respects a user’s desire for privacy while still delivering personalized ads based on their interests, which drives high ROI for advertisers."
msgstr ""

msgid "It is scheduled to start {campaignStartFromNow}."
msgstr ""

msgid "Key copied"
msgstr ""

msgid "Keypairs"
msgstr ""

msgid "Language acknowledgement is required"
msgstr ""

msgid "Last 30 days"
msgstr ""

msgid "Last 7 days"
msgstr ""

msgid "Last month"
msgstr ""

msgid "Learn More"
msgstr ""

msgid "Let Brave pick categories for me."
msgstr ""

msgid "Lifetime Budget"
msgstr ""

msgid "Lifetime budget is required"
msgstr ""

msgid "Lifetime budget must be ${MIN_PER_CAMPAIGN} or more"
msgstr ""

msgid "Lifetime budget must be higher for date range provided. Minimum ${min}."
msgstr ""

msgid "Linux"
msgstr ""

msgid "Locations"
msgstr ""

msgid "Log in"
msgstr ""

msgid "Log into your Brave Ads account"
msgstr ""

#~ msgid "Logging in"
#~ msgstr ""

msgid "Logout"
msgstr ""

msgid "macOS"
msgstr ""

msgid "Make payment & submit for approval"
msgstr ""

msgid "Make sure to include your brand name in the title OR body of the ad. Otherwise, it will be rejected."
msgstr ""

msgid "Max 30 Characters"
msgstr ""

msgid "Max 60 Characters"
msgstr ""

msgid "Modifying an ad will immediately put it into review. This means it will no longer be shown to users until it is approved."
msgstr ""

msgid "Modifying an ad will immediately put it into review. This means it will no longer be shown to users until it is approved. This ad is also shared across {campaignLength} campaigns. Any modifications made will be effective for all campaigns using this creative."
msgstr ""

msgid "Modifying the state of this ad will effect more than one campaign:"
msgstr ""

msgid "Name"
msgstr ""

msgid "Name your ad set to distinguish it from others."
msgstr ""

msgid "New"
msgstr ""

msgid "New ad"
msgstr ""

msgid "New API Key"
msgstr ""

msgid "New Campaign"
msgstr ""

msgid "New campaigns typically take up to 48 hours to review during a regular U.S. business week."
msgstr ""

msgid "New Keypair"
msgstr ""

msgid "New tab takeover"
msgstr ""

msgid "New tab takeovers"
msgstr ""

#~ msgid "New users are switching to Brave Search every single day. Don't miss out on meeting their intent to shop your product or services"
#~ msgstr ""

msgid "New users are switching to Brave Search every single day. Don't miss out on meeting their intent to shop your product or services."
msgstr ""

msgid "Newsfeed"
msgstr ""

msgid "Newsfeed Ad"
msgstr ""

msgid "Newsfeed ads"
msgstr ""

msgid "Next"
msgstr ""

msgid "No"
msgstr ""

msgid "No {billingType} pricing available for {label}, contact selfserve@brave.com for help"
msgstr ""

#~ msgid "no additional charge will be applied."
#~ msgstr ""

msgid "No conversion URL setup"
msgstr ""

msgid "No file result"
msgstr ""

msgid "No previous ads available"
msgstr ""

msgid "no session created"
msgstr ""

#~ msgid "Not automatically redirected? Click this link to go to the dashboard."
#~ msgstr ""

msgid "Notes"
msgstr ""

msgid "Notification"
msgstr ""

msgid "Notification ad"
msgstr ""

msgid "Notification ads"
msgstr ""

msgid "Observation Window"
msgstr ""

msgid "Observation window must be 1, 7, or 30 days."
msgstr ""

msgid "Observation window required."
msgstr ""

msgid "On / Off"
msgstr ""

msgid "On a podcast I listen to"
msgstr ""

msgid "On/Off"
msgstr ""

msgid "Once confirmed, your account’s keypair will be replaced with the new keypair."
msgstr ""

#~ msgid "Or email <0>searchads@brave.com</0>"
#~ msgstr ""

msgid "or sign in using a password"
msgstr ""

msgid "or sign in using a secure link"
msgstr ""

msgid "OS"
msgstr ""

msgid "Other (please specify)"
msgstr ""

msgid "Other accounts"
msgstr ""

msgid "Other source"
msgstr ""

msgid "Password"
msgstr ""

msgid "Pause"
msgstr ""

msgid "Pay with BAT to get <0>50% off</0> your first self-managed campaign."
msgstr ""

msgid "Payment"
msgstr ""

msgid "Payment acknowledgement is required"
msgstr ""

msgid "Payment is required before launching your campaign."
msgstr ""

msgid "Performance Report"
msgstr ""

msgid "Platforms"
msgstr ""

msgid "Please ask your Account Manager for reports on campaigns of this format."
msgstr ""

msgid "Please confirm your account’s new private key:"
msgstr ""

msgid "Please enter a username and password"
msgstr ""

msgid "Please enter a valid domain, for example: brave.com"
msgstr ""

msgid "Please enter a valid email address"
msgstr ""

msgid "Please enter a valid URL, for example https://brave.com"
msgstr ""

msgid "Please enter a valid URL, for example https://brave.com/product/*"
msgstr ""

msgid "Please enter an email."
msgstr ""

msgid "Please let us know how you heard about Brave Ads"
msgstr ""

msgid "Please let us know why you're interested in Brave Ads"
msgstr ""

msgid "Please refresh the page to try again, or email <0/> for assistance."
msgstr ""

msgid "Please see our <0>Advertiser Privacy Policy</0> and <1>Terms of Service</1> applicable to Brave Ads."
msgstr ""

msgid "Please specify how you heard about Brave Ads"
msgstr ""

msgid "Preparing file for upload..."
msgstr ""

msgid "Price"
msgstr ""

msgid "Price is a required field"
msgstr ""

msgid "Pricing"
msgstr ""

msgid "Pricing Type"
msgstr ""

msgid "Pricing type is a required field"
msgstr ""

msgid "Pricing type must be CPM, CPC, or CPSV"
msgstr ""

msgid "Primary region of business"
msgstr ""

msgid "Primary region of business is required"
msgstr ""

msgid "Private Key"
msgstr ""

msgid "Proceed"
msgstr ""

msgid "Profile"
msgstr ""

msgid "Profile API Key"
msgstr ""

msgid "Profile Details"
msgstr ""

msgid "Query parameter"
msgstr ""

msgid "Query string parameters"
msgstr ""

msgid "Reach 70+ million highly engaged, otherwise unreachable users."
msgstr ""

msgid "Reach new audiences you're missing"
msgstr ""

msgid "Ready to reach your first million users? Start your first campaign today."
msgstr ""

msgid "Remove"
msgstr ""

msgid "Remove Conversion tracking"
msgstr ""

#~ msgid "Request another link."
#~ msgstr ""

msgid "Return"
msgstr ""

msgid "Return to dashboard"
msgstr ""

msgid "Review"
msgstr ""

msgid "Review ads"
msgstr ""

#~ msgid "Risk Free"
#~ msgstr ""

msgid "Risk free trial for up to 30 days"
msgstr ""

msgid "Sample Queries"
msgstr ""

msgid "Save"
msgstr ""

msgid "Save ad"
msgstr ""

msgid "Saving..."
msgstr ""

msgid "Search homepage"
msgstr ""

msgid "Search keyword"
msgstr ""

msgid "Search keyword ads"
msgstr ""

msgid "searchads@brave.com"
msgstr ""

msgid "Select Account"
msgstr ""

msgid "Select one campaign to clone"
msgstr ""

msgid "Select one campaign to edit"
msgstr ""

msgid "Select the ads you would like to include in this ad set. Only checked ads are included."
msgstr ""

msgid "Select the audience segments to target"
msgstr ""

msgid "Select the geographic regions where your ads will be shown."
msgstr ""

msgid "Select the interest segments and platforms you would like to target."
msgstr ""

msgid "Select the platforms to target"
msgstr ""

msgid "Selected ads"
msgstr ""

msgid "Set up a trial campaign in minutes."
msgstr ""

msgid "Setup Progress"
msgstr ""

msgid "Simple ads to reach digital trend-setters around the world"
msgstr ""

msgid "Site Visit Rate"
msgstr ""

msgid "Site visits"
msgstr ""

msgid "Site Visits"
msgstr ""

msgid "Skip survey and continue"
msgstr ""

msgid "Something went wrong."
msgstr ""

msgid "Spend"
msgstr ""

msgid "Sponsored"
msgstr ""

msgid "Start"
msgstr ""

msgid "Start a campaign with BAT"
msgstr ""

msgid "Start Date"
msgstr ""

msgid "Start Date is required"
msgstr ""

msgid "Start Date must be minimum of 2 days from today"
msgstr ""

msgid "Start fast and experiment"
msgstr ""

msgid "Start Time"
msgstr ""

msgid "Start your company profile"
msgstr ""

msgid "State"
msgstr ""

msgid "Status"
msgstr ""

msgid "Street address"
msgstr ""

msgid "Street address is required"
msgstr ""

msgid "Submit"
msgstr ""

msgid "Success! Check your email."
msgstr ""

#~ msgid "Successfully logged in!"
#~ msgstr ""

msgid "Support"
msgstr ""

msgid "Take our survey!"
msgstr ""

#~ msgid "Tap into more than <0>9 billion</0> annual Web searches"
#~ msgstr ""

msgid "Target URL"
msgstr ""

msgid "Target URL is a required field"
msgstr ""

msgid "Target URL is not valid"
msgstr ""

msgid "Target URL not validated"
msgstr ""

msgid "Targeting"
msgstr ""

msgid "Tell us why you're interested in Brave Ads"
msgstr ""

msgid "Terms & Conditions acknowledgement is required"
msgstr ""

msgid "The <0>Basic Attention Token (BAT)</0> is Brave’s native crypto token. Brave Browser users who opt into <1>Brave Rewards</1> can earn BAT just for opting into viewing ads from the Brave Ads network. Earners can hold BAT like any other crypto asset, trade it, cash it in, or even donate it to their favorite creators."
msgstr ""

msgid "The campaign will likely stop running before the end date provided due to budget constraints."
msgstr ""

#~ msgid "The current balance for your account that you can attribute to a new Campaign."
#~ msgstr ""

msgid "The current value for this metric may be an underestimate."
msgstr ""

msgid "The following query string parameters will be added to your landing page URLs. This will allow you to track the performance of your ads."
msgstr ""

msgid "The magic link you have requested has either expired or has already been used. Please return to the login page and try again."
msgstr ""

msgid "The username or password did not match our records."
msgstr ""

msgid "This is a news display ad, it wll look like part of the news feed."
msgstr ""

msgid "This key is unique to you, make sure to safely store it and avoid sharing it with others to prevent unauthorized access."
msgstr ""

msgid "This month"
msgstr ""

msgid "Time Zone"
msgstr ""

msgid "Title"
msgstr ""

msgid "Title Preview"
msgstr ""

msgid "To"
msgstr ""

msgid "To protect user’s privacy, verified ad conversion data is encrypted so that the identities of converted users remain anonymous to Brave. You can decrypt the conversion data in the CSV file by providing your private key here. If no key is provided, you will receive the encrypted conversion data. Your private key will never be sent to or stored on any Brave servers."
msgstr ""

msgid "Today"
msgstr ""

#~ msgid "Try a free one-month test to see how Brave Search Ads perform for your brand."
#~ msgstr ""

msgid "Try a free one-month test to see how Brave Search Ads perform for your business."
msgstr ""

msgid "Unable to clone campaign"
msgstr ""

msgid "Unable to create a new campaign"
msgstr ""

msgid "Unable to Create Campaign."
msgstr ""

msgid "Unable to create payment session. Please try again."
msgstr ""

msgid "Unable to decrypt conversion data"
msgstr ""

msgid "Unable to download CSV"
msgstr ""

msgid "Unable to get campaign information for ad"
msgstr ""

msgid "Unable to get profile information"
msgstr ""

msgid "Unable to load ad"
msgstr ""

msgid "Unable to load reporting details for campaign: {0}"
msgstr ""

#~ msgid "Unable to login, link has expired or has already been used."
#~ msgstr ""

msgid "Unable to login."
msgstr ""

msgid "Unable to register your organization at this time. Please try again later."
msgstr ""

msgid "Unable to retrieve Campaign data."
msgstr ""

msgid "Unable to retrieve images"
msgstr ""

msgid "Unable to save ad"
msgstr ""

msgid "Unable to submit registration"
msgstr ""

msgid "Unable to update Advertiser."
msgstr ""

msgid "Unable to Update Campaign."
msgstr ""

msgid "Unable to upload image"
msgstr ""

msgid "Unexpected error validating your credentials. Please try again later."
msgstr ""

msgid "Unknown"
msgstr ""

msgid "Update & Submit For Approval"
msgstr ""

msgid "Upload"
msgstr ""

msgid "Upload Image"
msgstr ""

msgid "Upload new image"
msgstr ""

msgid "Uploaded images can be shared across different ad sets within a Campaign. For best quality, upload images at 900x750px resolution. Images will be automatically scaled to this size."
msgstr ""

msgid "Uploading file..."
msgstr ""

msgid "Upvotes"
msgstr ""

msgid "URL is valid."
msgstr ""

msgid "URL must not contain any whitespace"
msgstr ""

msgid "URL must start with https://"
msgstr ""

msgid "URL Pattern"
msgstr ""

msgid "URL should have a trailing asterisk - Example: https://brave.com/products/*"
msgstr ""

msgid "Use existing ads"
msgstr ""

msgid "Validating URL..."
msgstr ""

msgid "Value"
msgstr ""

msgid "Verified Conversions Report"
msgstr ""

msgid "View profile"
msgstr ""

msgid "View-through Conversions"
msgstr ""

msgid "Visit Help Center"
msgstr ""

msgid "We value your feedback and would love to hear your thoughts on your recent experience. <0/>Your input will help us improve the Brave Ads platform to better meet your needs. <1/>It only takes a few minutes and your participation will be greatly appreciated! <2/>Thank you for using Brave Ads! <3/>"
msgstr ""

msgid "Website must not contain any whitespace"
msgstr ""

msgid "Website must start with https://"
msgstr ""

msgid "What is Brave Ads?"
msgstr ""

msgid "What’s BAT?"
msgstr ""

msgid "What’s Brave?"
msgstr ""

msgid "Where did you hear about Brave Ads"
msgstr ""

msgid "While looking up alternatives to Google Ads"
msgstr ""

msgid "Why use Brave Ads?"
msgstr ""

msgid "Windows"
msgstr ""

msgid "Work email"
msgstr ""

msgid "Yes"
msgstr ""

msgid "You are about to close this campaign."
msgstr ""

msgid "You are attempting to create a new keypair, this will replace any of your account’s existing keypairs. Please note, previous keypairs cannot be retrieved or used once replaced."
msgstr ""

msgid "You are logging into the Brave Ads Manager Dashboard."
msgstr ""

msgid "You have {0} errors that must be fixed before submitting."
msgstr ""

msgid "You have unsaved changes"
msgstr ""

msgid "You may have access to multiple accounts. Switch between them here."
msgstr ""

msgid "You will soon receive an email with the next steps of registration. While you wait, we recommend you check out our help center to get helpful information for getting started with Brave Ads."
msgstr ""

msgid "Your account has a balance of {0} USD. This will be applied to your campaign"
msgstr ""

#~ msgid "Your account has a balance of ${0} USD. This will be applied to your campaign"
#~ msgstr ""

msgid "Your account’s new private key will be:"
msgstr ""

#~ msgid "Your account’s new public key will be:"
#~ msgstr ""

msgid "Your account’s public key:"
msgstr ""

msgid "Your campaign has been created and is now being reviewed by our ads team. <0/>We’ll be in contact as soon as your campaign is approved and activated."
msgstr ""

msgid "Your campaign has been updated! <0/>If you added new ads, we’ll be in contact as soon as they are approved and activated. <1/>Thank you for using Brave Ads!"
msgstr ""

msgid "Your current account balance"
msgstr ""

msgid "Your trial campaign will be reviewed by an Account Manager. Add any notes or questions for them here."
msgstr ""

msgid "Zip / Postal Code"
msgstr ""
